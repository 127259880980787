import React from "react";
import { next, prev } from "./imgs";
import { useSelector } from "react-redux";

const StepperControl = ({ currentStep, handleClick, steps }) => {
  const {stepperNext}=useSelector(state=>state.events)
  return (
    <section className="w-full max-sm:bg-[#0A090C] max-sm:px-[20px] pb-[32px] pt-[12px] max-sm:z-10 max-sm:border-t border-[#1F242F] right-0 max-sm:fixed max-sm:bottom-0 max-sm:grid grid-cols-2 mt-[10px] flex justify-start items-start gap-[24px]">
        {!(currentStep === 1) ? (
          <button 
          onClick={()=>handleClick()}
            className={`px-[14px] py-[10px] flex items-center max-sm:justify-center gap-[6px] btn-prev `}
          >
            <img src={prev} alt="" />
            <h1 className="text-[14px] max-sm:text-[16px] font-[500]">Orqaga</h1>
          </button>
        ):(
          <div></div>
        )}
        <button disabled={false} onClick={()=>handleClick('next')} className={`${stepperNext && "opacity-[0.7]"} px-[14px] py-[10px] flex items-center max-sm:justify-center gap-[6px] bg-[#2970FF] rounded-[8px]`}>
          <h1 className="text-[14px] max-sm:text-[16px] font-[500]">{currentStep===steps.length?"Yuborish":"Keyingisi"}</h1>
          <img src={next} alt="" />
        </button>
    </section>
  );
};

export default StepperControl;
