 import React from "react";
import { mobilel, mobiler, next, prev } from "./imgs";
import { useSelector } from "react-redux";

const StepperMobile = ({ currentStep, handleClick, steps }) => {
    const {stepperNext}=useSelector(state=>state.events)
  return (
    <section className="sm:hidden w-full flex justify-between items-center ">
      <button
        disabled={currentStep === 1 && true}
        onClick={() => handleClick()}
        className={`${
          currentStep === 1 && "opacity-[0.5]"
        } px-[14px] py-[10px] `}
      >
        <img src={mobilel} alt="" />
      </button>
      <div className="text-[26px] font-[500] flex">
        <h1>{currentStep}</h1>
        <h1>/</h1>
        <h1>9</h1>
      </div>
      <button
      disabled={stepperNext}
        onClick={() => handleClick("next")}
        className={`px-[14px] py-[10px] ${stepperNext &&"opacity-[0.5]"}`}
      >
        <img src={mobiler} alt="" />
      </button>
    </section>
  );
};

export default StepperMobile;
