import React, { useEffect, useState } from "react";
import MapContainer from "./map";
import SearchResult from "./search-results";
import { useDispatch, useSelector } from "react-redux";
import {
  setFetchData,
  setPlaceData,
  setStepperCheck,
} from "../../../reducer/redux";
import YandexMap from "../map";
const PlaceLocation = () => {
  const dispatch = useDispatch();
  const { fetchPlace } = useSelector((state) => state.events);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (fetchPlace.latitude && fetchPlace.longitude && fetchPlace.info) {
      dispatch(setStepperCheck(false));
    } else {
      dispatch(setStepperCheck(true));
    }
  }, [fetchPlace]);
  
  return (
    <div>
      <h1 className="text-[29px] font-[600]">Joylashuv ma’lumotlari</h1>
      <div className="w-full h-[1px] bg-[#333] my-[16px]"></div>
      <section className="flex flex-col gap-[32px]">
        <SearchResult searchText={searchText} />
        <YandexMap />
        <div className="max-md:w-full w-[350px] flex flex-col gap-2 mt-[50px]">
          <label
            className="text-[#CECFD2] text-[14px] font-[500]"
            htmlFor="text"
          >
            Joy haqida ma’lumotlar
          </label>
          <textarea
            className={`${
              false ? " border-red-400" : "border-[#fdfdfd4d]"
            } max-md:w-full h-[150px] bg-[#1d1d20] border-[1px] border-solid contact-input input-style  p-[10px] text-[16px] font-[400] input-form`}
            type="text"
            placeholder={"Joy haqida"}
            onChange={(e) =>
              dispatch(
                setFetchData({
                  ...fetchPlace,
                  info: e.target.value,
                })
              )
            }
            name="info"
            value={fetchPlace.info}
            // value={formData.full_name}
            // onChange={handleChange}
          />
          {/* <label className="text-[red]" htmlFor="text">{errorMessage?.full_name && t("error_msg")}</label> */}
        </div>
      </section>
    </div>
  );
};

export default PlaceLocation;
