import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { background, closeModal, successCheck } from "../imgs";

export default function SuccessModal({ handleClose, isOpen, handleBackMenu }) {
  const navigate = useNavigate();
  const handleBack=(navigate)=>{
    handleClose()
    navigate('/search-place')
  }
  useEffect(() => {
    const body = document.querySelector(".app");
    if (isOpen) {
      body.classList.add("blur-effect");
    } else {
      body.classList.remove("blur-effect");
    }
  }, [isOpen]);
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[999]" onClose={handleBack}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#1F242F] opacity-[0.5]" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-[#0c111d] p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    <main className="z-10">
                      <div className="flex justify-between items-start relative z-10">
                        <img
                          className="bg-[#079455]  rounded-full p-[12px]"
                          src={successCheck}
                          alt=""
                        />
                        <img
                          className="w-[24px] h-[24px]"
                          src={closeModal}
                          alt=""
                        />
                        <img
                          className=" absolute left-[-25px]  top-[-25px]"
                          src={background}
                          alt=""
                        />
                      </div>
                    </main>
                  </Dialog.Title>
                  <section onClick={handleBackMenu} className="z-20">
                    <h1 className="text-[18px] font-[600] text-[#F5F5F6] z-20 mt-[16px]">
                      Arizangiz qabul qilindi
                    </h1>
                    <p className="text-[14px] font-[400] text-[#94969C]">
                      Arizangiz moderatorlarimiz tomonidan ko’rib chiqilib, 24
                      soat ichida javob beriladi
                    </p>
                    <button className="rounded-[8px] px-[14px] py-[10px] mt-[32px] bg-[#2970FF] w-full flex justify-center items-center">
                      Bosh sahifaga qaytish
                    </button>
                  </section>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
