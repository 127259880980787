import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFetchData, setStepperCheck } from "../../../reducer/redux";

const PlaceAbout = () => {
  const dispatch = useDispatch();
  const { fetchPlace } = useSelector((state) => state.events);
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      setFetchData({
        ...fetchPlace,
        [name]: value,
      })
    );
  };
  useEffect(() => {
    if (fetchPlace.phone.length == 13 && fetchPlace.name.length > 0) {
      dispatch(setStepperCheck(false));
    } else {
      dispatch(setStepperCheck(true));
    }
  }, [fetchPlace]);

  useEffect(()=>{
    const year=new Date().getFullYear()
    const day=new Date().getDate()
    const month=new Date().getMonth()
    dispatch(setFetchData({
      ...fetchPlace,
      created_at: `${year}-${month+1}-${day}`,
    }))
  },[])
  return (
    <main>
      <div className="py-[16px] mb-[24px]">
        <h1 className="text-[29px] font-[600]">Joy haqida</h1>
        <div className="w-full h-[1px] bg-[#333] my-[16px]"></div>
        <form action="" className="flex flex-col gap-[32px] mt-[24px]">
          <div className="max-md:w-full w-[350px] flex flex-col gap-2">
            <label
              className="text-[#CECFD2] text-[14px] font-[500]"
              htmlFor="text"
            >
              Joy nomi
            </label>
            <input
              className={`${
                false ? " border-red-400" : "border-[#fdfdfd4d]"
              } border-[1px] border-solid contact-input input-style  p-[10px] bg-transparent text-[16px] font-[400] input-form`}
              type="text"
              onChange={handleChange}
              placeholder={"Mini market"}
              value={fetchPlace.name}
              name="name"
              // value={formData.full_name}
              // onChange={handleChange}
            />
            {/* <label className="text-[red]" htmlFor="text">{errorMessage?.full_name && t("error_msg")}</label> */}
          </div>
          <div className="max-md:w-full w-[350px] flex flex-col gap-2">
            <PhoneNumber phone={fetchPlace.phone} />
          </div>
        </form>
      </div>
    </main>
  );
};

export default PlaceAbout;

const PhoneNumber = ({ error, phone, value }) => {
  const { fetchPlace } = useSelector((state) => state.events);
  const [phoneNumber, setPhoneNumber] = useState({ phone: phone ? phone : "" });
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    let inputValue = e.target.value.replace(/[^0-9]/g, "");
    // Ensure the input doesn't exceed 12 digits
    inputValue = inputValue.slice(0, 12);
    const formattedValue = [];
    for (let i = 0; i < inputValue.length; i++) {
      if (i === 0) {
        formattedValue.push("+");
      }
      if (i === 3) {
        formattedValue.push(" (");
      }
      if (i === 5) {
        formattedValue.push(") ");
      }
      if (i === 8 || i === 10) {
        formattedValue.push(" ");
      }
      formattedValue.push(inputValue[i]);
    }
    const finalValue = formattedValue.join("");
    const { name } = e.target;
    setPhoneNumber({
      ...phoneNumber,
      [name]: finalValue,
    });
  };

  const handleInputFocus = () => {
    if (!phoneNumber.phone) {
      setPhoneNumber({
        ...phoneNumber,
        phone: "+998",
      });
    }
  };

  useEffect(() => {
    const phone = phoneNumber.phone;
    const phoneNumberWithPlus = phone.replace(/[^0-9+]/g, "");
    dispatch(
      setFetchData({
        ...fetchPlace,
        phone: phoneNumberWithPlus,
      })
    );
  }, [phoneNumber, dispatch]);

  return (
    <input
      className={`${
        false ? " border-red-400" : "border-[#fdfdfd4d]"
      } border-[1px] border-solid contact-input input-style  p-[10px] bg-transparent text-[16px] font-[400] input-form`}
      placeholder="+998 (88) 123 45 67"
      name="phone"
      value={phoneNumber.phone}
      onChange={handleInputChange}
      onFocus={handleInputFocus}
    />
  );
};
