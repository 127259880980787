import React, { useEffect, useState } from "react";
import { setFetchData, setStepperCheck } from "../../../reducer/redux";
import { useDispatch, useSelector } from "react-redux";

const Applicant = () => {
  const dispatch = useDispatch();
  const { fetchPlace } = useSelector((state) => state.events);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      setFetchData({
        ...fetchPlace,
        [name]: value,
      })
    );
  };

  

  useEffect(() => {
    const { applicantPhone, applicantName } = fetchPlace;
    const isValid = applicantPhone.length >= 13 && applicantName.length > 0;
    dispatch(setStepperCheck(!isValid));
  }, [dispatch, fetchPlace]);
  return (
    <div>
      <h1 className="text-[29px] font-[600]">Arizachi ma’lumoti</h1>
      <div className="w-full h-[1px] bg-[#333] my-[16px]"></div>
      <form action="" className="flex flex-col gap-[32px] mt-[24px]">
        <div className="max-sm:w-full w-[350px] flex flex-col gap-2">
          <label
            className="text-[#CECFD2] text-[14px] font-[500]"
            htmlFor="text"
          >
            Familiya va ism
          </label>
          <input
            onChange={handleChange}
            className="border-[#fdfdfd4d] border-[1px] border-solid contact-input input-style p-[10px] bg-transparent text-[16px] font-[400] input-form"
            type="text"
            placeholder={"Familiya ism"}
            name="applicantName"
            value={fetchPlace.applicantName}
          />
        </div>
        <div className="max-sm:w-full w-[350px] flex flex-col gap-2">
          <label
            className="text-[#CECFD2] text-[14px] font-[500]"
            htmlFor="text"
          >
            Telefon raqami
          </label>
          <PhoneNumber />
        </div>
      </form>
    </div>
  );
};

export default Applicant;

const PhoneNumber = () => {
  const dispatch = useDispatch();
  const { fetchPlace } = useSelector((state) => state.events);
  const [phoneNumber, setPhoneNumber] = useState(
    fetchPlace.applicantPhone || "+998"
  );

  const handleInputChange = (e) => {
    let inputValue = e.target.value.replace(/[^0-9]/g, "");
    inputValue = inputValue.slice(0, 12);
    const formattedValue = [];
    for (let i = 0; i < inputValue.length; i++) {
      if (i === 0) {
        formattedValue.push("+");
      }
      if (i === 3) {
        formattedValue.push(" (");
      }
      if (i === 5) {
        formattedValue.push(") ");
      }
      if (i === 8 || i === 10) {
        formattedValue.push(" ");
      }
      formattedValue.push(inputValue[i]);
    }
    const finalValue = formattedValue.join("");
    setPhoneNumber(finalValue);
  };

  useEffect(() => {
    const phoneNumberWithPlus = phoneNumber.replace(/[^0-9+]/g, "");
    dispatch(
      setFetchData({
        ...fetchPlace,
        applicantPhone: phoneNumberWithPlus,
      })
    );
  }, [phoneNumber, dispatch]);

  const handleInputFocus = () => {
    if (!phoneNumber) {
      setPhoneNumber("+998");
    }
  };

  return (
    <input
      className="border-[#fdfdfd4d] border-[1px] border-solid contact-input input-style p-[10px] bg-transparent text-[16px] font-[400] input-form"
      placeholder="+998 (88) 123 45 67"
      name="applicantPhone"
      value={phoneNumber}
      onChange={handleInputChange}
      onFocus={handleInputFocus}
    />
  );
};
