import React, { useEffect, useState } from "react";
import TimezoneSelect from "react-timezone-select";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { setFetchData, setStepperCheck } from "../../../reducer/redux";
function TimeZone() {
  const { fetchPlace } = useSelector((state) => state.events);
  const [selectedTimezone, setSelectedTimezone] = useState(
    fetchPlace.timeZone
      ? fetchPlace.timeZone
      : Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setStepperCheck(false));
  }, [fetchPlace]);
  useEffect(() => {
    dispatch(
      setFetchData({
        ...fetchPlace,
        timezone: selectedTimezone,
      })
    );
  }, [selectedTimezone]);
  return (
    <div>
      <h1 className="text-[29px] font-[600]">Vaqt mintaqasi</h1>
      <div className="w-full h-[1px] bg-[#333] my-[16px]"></div>
      <div className="select-wrapper max-w-[400px] bg-black">
        <TimezoneSelect
          className="react-select-container"
          classNamePrefix="react-select"
          value={selectedTimezone}
          onChange={setSelectedTimezone}
        />
      </div>
    </div>
  );
}

export default TimeZone;
