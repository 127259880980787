import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { easeInOut, motion } from "framer-motion";
import { close, search } from "../imgs";
import { Listbox } from "@headlessui/react";
import { Transition } from "react-spring";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import {
  setFetchData,
  setPlaceData,
  setQueryPlace,
  setSelectPlace,
} from "../../../reducer/redux";
const SearchResult = () => {
  const dispatch = useDispatch();
  const { searchQueryPlace, selectPlace, fetchPlace } = useSelector(
    (state) => state.events
  );
  const [people, setPeople] = useState([]);
  const [query, setQuery] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    try {
      const res = await axios.get(
        `https://search-maps.yandex.ru/v1/?text=${query}&type=geo&lang=en_US&apikey=a734eb80-8279-45ed-bcdb-d26d507a02bd`
      );
      dispatch(setQueryPlace(res?.data?.features));
    } catch (error) {
      setError("Error fetching data.");
    }
  };

  const handleSearch = (e) => {
    setQuery(e.target.value);
    setError("");
  };

  const handleDelete = () => {
    setQuery("");
    setError("");
  };

  useEffect(() => {
    setPeople(
      searchQueryPlace.map((person, idx) => ({
        name: person?.properties?.name,
      }))
    );
  }, [searchQueryPlace]);
console.log(searchQueryPlace)
  useEffect(() => {
    const coordinates = searchQueryPlace.find((place, idx) => {
      return place.properties?.name === selectPlace.name;
    });
    
    dispatch(
      setFetchData({
        ...fetchPlace,
        latitude: coordinates?.geometry?.coordinates[0],
        longitude: coordinates?.geometry?.coordinates[1],
        full_address:selectPlace?.name
      })
    );
    // Do something with coordinates if needed
  }, [selectPlace, searchQueryPlace]);

  return (
    <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-4 w-full z-20">
      <section className="flex flex-col gap-1">
        <label className="text-[14px] font-[500] text-[#CECFD2]" htmlFor="text">
          Joyni izlash
        </label>
        <div className="flex justify-start items-center w-full gap-[16px]">
          <div className="relative w-full">
            <input
              onChange={handleSearch}
              className={`${
                error ? "border-red-400" : "border-[#fdfdfd4d]"
              } w-full contact-input text-[#85888E] outline-none bg-[#1d1d20] px-[14px] py-[8px] rounded-[8px] shadow-input border-[#333741] border-[1px]`}
              type="text"
              value={query}
              placeholder="Joy nomini kiriting"
            />
            <label className="text-[red]" htmlFor="text">
              {error && error}
            </label>
            <motion.img
              onClick={handleDelete}
              initial={{ scale: 0 }}
              animate={{ scale: query ? 1 : 0 }}
              className="w-[20px] absolute right-[14px] top-[12px] cursor-pointer"
              src={close}
              alt=""
            />
          </div>
          <button
            onClick={handleSubmit}
            className="w-[50px] px-[14px] py-[12px] flex gap-[6px] bg-[#2970FF] rounded-[8px]"
          >
            <img className="" src={search} alt="" />
          </button>
        </div>
      </section>
      {people.length > 0 && (
        <section className="w-full flex items-end ">
          <Listbox
            value={selectPlace}
            onChange={(e) => dispatch(setSelectPlace(e))}
          >
            <div className="relative mt-1 w-full">
              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-[#1d1d20] py-[10px] pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                <span className="block truncate">{selectPlace?.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Listbox.Options className="absolute list_option  mt-1 max-h-60 min-w-[200px] overflow-auto rounded-md bg-[#1d1d20] text-[#85888E] py-1 text-base shadow-lg ">
                {people.map((person, idx) => (
                  <Listbox.Option
                    key={idx}
                    value={person}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 px-2 pr-4 ${
                        active ? "bg-[#aca8a8] text-[#e0e2e5]" : "text-gray-900"
                      }`
                    }
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={`block truncate text-[#CECFD2] ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {person.name}
                          </span>
                          {selected ? (
                            <CheckIcon className="h-5 w-5 text-[#bfc0c2] ml-2" />
                          ) : null}
                        </div>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </Listbox>
        </section>
      )}
    </div>
  );
};

export default SearchResult;
