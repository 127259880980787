import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFetchData, setStepperCheck } from "../../../reducer/redux";
const weekDays = [
  { id: 1, weekName: "Dushanba", dayOfWeek: 1 },
  { id: 2, weekName: "Seshanba", dayOfWeek: 2 },
  { id: 3, weekName: "Chorshanba", dayOfWeek: 3 },
  { id: 4, weekName: "Payshanba", dayOfWeek: 4 },
  { id: 5, weekName: "Juma", dayOfWeek: 5 },
  { id: 6, weekName: "Shanba", dayOfWeek: 6 },
  { id: 7, weekName: "Yakshanba", dayOfWeek: 7 },
];
const PlaceTime = () => {
  const dispatch = useDispatch();
  const { fetchPlace } = useSelector((state) => state.events);
  const [errorMessage, setErrorMessage] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    imageUrl: "",
    phone: "",
    descriptionRU: "",
    descriptionUz: "",
    workDayList: fetchPlace.work_days ? fetchPlace.work_days : [],
    isNotDaysOff: false,
  });
  const handleChangeData = (e, idx) => {
    let value = e.target.value.replace(/[^0-9:]/g, "");
    value = value.slice(0, 5);
    const name = e.target.name;
    const formattedValue = [];

    if (/^[\d:]+$/.test(value)) {
      for (let i = 0; i < value.length; i++) {
        if (i === 2 && value[i] !== ":") {
          formattedValue.push(":");
        }
        formattedValue.push(value[i]);
      }
    }
    const finalValue = formattedValue.join("");
    setFormData((prevState) => ({
      ...prevState,
      workDayList: prevState.workDayList.map((day, index) =>
        day.dayOfWeek === idx ? { ...day, [name]: finalValue } : day
      ),
    }));
  };

  const handleChangeWeek = (index) => {
    const dayOfWeekExists = formData.workDayList.some(
      (day) => day.dayOfWeek === index
    );

    if (!dayOfWeekExists) {
      // If the day of the week is checked, add it to the workDayList
      const updatedWorkDayList = [
        ...formData.workDayList,
        {
          dayOfWeek: index,
          startTime: "09:00",
          endTime: "18:00",
        },
      ];
      setFormData((prevState) => ({
        ...prevState,
        workDayList: updatedWorkDayList,
      }));
    } else {
      // If the day of the week is unchecked, remove it from the workDayList
      const updatedWorkDayList = formData.workDayList.filter(
        (day) => day.dayOfWeek !== index
      );
      setFormData((prevState) => ({
        ...prevState,
        workDayList: updatedWorkDayList,
      }));
    }
  };
  const handleStatus = () => {
    setFormData((prevFormData) => {
      const updatedIsNotDaysOff = !prevFormData.isNotDaysOff;

      const weekDays = [
        { dayOfWeek: 1, startTime: "09:00", endTime: "18:00" },
        { dayOfWeek: 2, startTime: "09:00", endTime: "18:00" },
        { dayOfWeek: 3, startTime: "09:00", endTime: "18:00" },
        { dayOfWeek: 4, startTime: "09:00", endTime: "18:00" },
        { dayOfWeek: 5, startTime: "09:00", endTime: "18:00" },
        { dayOfWeek: 6, startTime: "09:00", endTime: "18:00" },
        { dayOfWeek: 7, startTime: "09:00", endTime: "18:00" },
      ];

      return {
        ...prevFormData,
        isNotDaysOff: updatedIsNotDaysOff,
        workDayList: updatedIsNotDaysOff ? weekDays : [],
      };
    });
  };

  useEffect(() => {
    dispatch(
      setFetchData({
        ...fetchPlace,
        work_days: formData.workDayList,
      })
    );
  }, [formData.workDayList]);
  useEffect(() => {
    if (fetchPlace.work_days.length > 0) {
      dispatch(setStepperCheck(false));
    } else {
      dispatch(setStepperCheck(true));
    }
  }, [fetchPlace]);
  return (
    <div>
      <h1 className="text-[29px] font-[600]">Ish vaqtlari</h1>
      <div className="w-full h-[1px] bg-[#333] my-[16px]"></div>
      <h1 className="text-[#fdfdfd] text-[20px] font-[500] mt-[40px] mb-[20px]">
        Hafta kunlari
      </h1>
      <div className="flex flex-col gap-[12px]">
        {weekDays.map((item, idx) => (
          <main
            key={idx}
            className="w-full grid grid-cols-3 max-sm:grid-cols-2 gap-[48px]"
          >
            <section className="flex items-center gap-[12px] w-[150px]">
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={formData.workDayList.some(
                    (c) => c.dayOfWeek === item.id
                  )}
                  onChange={() => handleChangeWeek(item.dayOfWeek)}
                />

                <div className="relative w-[36px] h-[20px] bg-[#333] peer-focus:outline-none  rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all  peer-checked:bg-[#2970FF]"></div>
              </label>
              <h1 className="text-[16px] font-[500]">{item.weekName}</h1>
            </section>
            <section className="flex items-center justify-start gap-[12px]">
              <input
                disabled={
                  formData?.workDayList.find(
                    (c) => c.dayOfWeek === item.dayOfWeek
                  )
                    ? false
                    : true
                }
                className={`text-[16px] max-w-[120px] max-sm:w-full border-[#333741] max-sm:px-[8px] text-center px-[16px] rounded-[8px] bg-[#1d1d20] outline-none text-h1 focus:shadow-btn border-[1px] border-solid flex py-[8px] `}
                type="text"
                placeholder="09:00"
                name="startTime"
                value={
                  formData.workDayList.find((c) => c.dayOfWeek === item.id)
                    ?.startTime || "" // Ensure it defaults to an empty string if undefined
                }
                onChange={(e) => handleChangeData(e, item.dayOfWeek)}
              />
              -
              <input
                disabled={
                  formData?.workDayList.find((c) => c.dayOfWeek === item.id)
                    ? false
                    : true
                }
                className={`text-[16px] max-w-[120px] max-sm:w-full max-sm:px-[8px] text-center px-[16px] border-[#333741] rounded-[8px] bg-[#1d1d20] outline-none text-h1 focus:shadow-btn border-[1px] border-solid flex py-[8px]`}
                type="text"
                placeholder="09:00"
                value={
                  formData.workDayList.find((c) => c.dayOfWeek === item.id)
                    ?.endTime || "" // Ensure it defaults to an empty string if undefined
                }
                name="endTime"
                onChange={(e) => handleChangeData(e, item.dayOfWeek)}
              />
            </section>
          </main>
        ))}
        <main className="w-full flex items-center gap-[48px] mt-[10px]">
          <section className="flex items-center gap-[12px]">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={formData?.isNotDaysOff}
                onChange={handleStatus}
                className="sr-only peer"
              />
              <div className="relative w-[36px] h-[20px] bg-[#333] peer-focus:outline-none  rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all  peer-checked:bg-[#2970FF]"></div>
            </label>
            <h1>Dam olish kunlarisiz</h1>
          </section>
        </main>
      </div>
    </div>
  );
};

export default PlaceTime;
