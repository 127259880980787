import React, { useEffect, useRef } from "react";
import { trash, upload } from "../imgs";
import { useDispatch, useSelector } from "react-redux";
import { setFetchData, setStepperCheck } from "../../../reducer/redux";

const Photos = () => {
  const dispatch = useDispatch();
  const { fetchPlace } = useSelector((state) => state.events);
  const fileInputRef = useRef(null);
  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };
  const handleFileUploaded = (e) => {
    const file = e.target.files[0];
    console.log(file)
    dispatch(
      setFetchData({
        ...fetchPlace,
        image: file,
      })
    );
  };
  useEffect(() => {
    if (fetchPlace.image) {
      dispatch(setStepperCheck(true));
    } else {
      dispatch(setStepperCheck(true));
    }
  }, [fetchPlace]);
  return (
    <div>
      <h1 className="text-[29px] font-[600]">Joy haqida</h1>
      <div className="w-full h-[1px] bg-[#333] my-[16px]"></div>
      <section className="flex justify-start max-sm:flex-col items-center gap-[24px]">
        <div
          onClick={handleFileInputClick}
          className="max-sm:w-full w-[200px] max-sm:h-[200px] h-[144px] flex flex-col justify-center items-center px-[24px] py-[16px] bg-[#1d1d20] rounded-[24px] border-[#333741] border-[2px] border-solid hover:border-[#2970FF] cursor-pointer"
        >
          <img src={upload} alt="" />
          <h1 className="text-[14px] mt-[12px] font-[500] text-[#FDFDFD]">
            Rasm yuklang
          </h1>
          <p className="mt-[4px] text-[12px] font-[400] text-[#94969C] text-center">
            SVG, PNG yoki JPG (max. 800x400px)
          </p>
        </div>
        {fetchPlace.image && (
          <div className="max-sm:w-full w-[200px] max-sm:h-[200px] h-[144px] relative">
            <img
              className="max-sm:w-full w-[200px] max-sm:h-[200px] h-[144px] object-cover rounded-[24px] "
              src={URL.createObjectURL(fetchPlace.image)}
              alt="sadsad"
            />
            <img
              className="bg-white rounded-full p-[5px] absolute right-[12px] cursor-pointer top-[12px]"
              src={trash}
              alt=""
            />
          </div>
        )}
      </section>
      <input
        multiple
        type="file"
        name="file"
        hidden
        ref={fileInputRef}
        onChange={handleFileUploaded}
        className="file-input"
      />
    </div>
  );
};

export default Photos;
