import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  defLang: "",
  searchText: "",
  place: [],
  locationSearch: "",
  locationPlaces: [],
  loading:false,
  editPlaceId:"",
  fetchPlace: {
    name: "",
    phone: "",
    phone2: "",
    image: "",
    latitude: "",
    longitude: "",
    place_type: {},
    work_start_time: "",
    work_end_time: "",
    work_days: [],
    website: "",
    instagram: "",
    telegram: "",
    telegram_bot: "",
    facebook: "",
    twitter: "",
    youtube: "",
    info: "",
    full_address: "",
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZonem,
    user_name: "",
    status: "",
    created_at: "",
    about: null,
    applicantName:"",
    applicantPhone:"",
    countPlace:1,
  },
  searchQueryPlace: [],
  selectPlace: null,
  stepperNext:true
};
export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setDefLang: (state, action) => {
      state.defLang = action.payload;
    },
    setSelectPlace: (state, action) => {
      state.selectPlace = action.payload;
    },
    setQueryPlace: (state, action) => {
      state.searchQueryPlace = action.payload;
      state.selectPlace = { name: state.searchQueryPlace[0]?.properties?.name };
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setFetchData: (state, action) => {
      state.fetchPlace = action.payload;
    },
    setPlaceData: (state, action) => {
      state.place = action.payload;
    },
    searchLocationState: (state, action) => {
      state.locationSearch = action.payload;
    },
    searchPlacesState: (state, action) => {
      state.locationPlaces = action.payload;
    },
    setStepperCheck: (state, action) => {
      state.stepperNext = action.payload;
    },
    setCountPlace:(state,action)=>{
      state.countPlace = action.payload;
    },
    setLoadingState:(state, action)=>{
      state.loading=action.payload
    },
    setEditPlace:(state, action)=>{
      state.editPlaceId=action.payload;
      
    }
  },
});

export const {
  setLoadingState,
  setCountPlace,
  setStepperCheck,
  setDefLang,
  setSearchText,
  setPlaceData,
  searchPlacesState,
  searchLocationState,
  setFetchData,
  setQueryPlace,
  setSelectPlace,
  setEditPlace,
} = eventsSlice.actions;
export default eventsSlice.reducer;
