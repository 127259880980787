import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFetchData, setStepperCheck } from "../../../reducer/redux";

const Links = () => {
  const { fetchPlace } = useSelector((state) => state.events);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      setFetchData({
        ...fetchPlace,
        [name]: value,
      })
    );
  };
  useEffect(() => {
    dispatch(setStepperCheck(false));
  }, [fetchPlace]);
  return (
    <div>
      <h1 className="text-[29px] font-[600]">Havolalar</h1>
      <div className="w-full h-[1px] bg-[#333] my-[16px]"></div>
      <form action="" className="flex flex-col gap-[32px] mt-[24px]">
        <div className="max-sm:w-full w-[350px] flex flex-col gap-2">
          <label
            className="text-[#CECFD2] text-[14px] font-[500]"
            htmlFor="text"
          >
            Web sayt uchun havola
          </label>
          <input
            className={`${
              false ? " border-red-400" : "border-[#fdfdfd4d]"
            } border-[1px] border-solid contact-input input-style  p-[10px] bg-transparent text-[16px] font-[400] input-form`}
            type="text"
            value={fetchPlace.website}
            placeholder={"www.example.com"}
            name="website"
            onChange={handleChange}
          />
          {/* <label className="text-[red]" htmlFor="text">{errorMessage?.full_name && t("error_msg")}</label> */}
        </div>
        <div className="max-sm:w-full w-[350px] flex flex-col gap-2">
          <label
            className="text-[#CECFD2] text-[14px] font-[500]"
            htmlFor="text"
          >
            Instagram sahifasi uchun havola
          </label>
          <input
            className={`${
              false ? " border-red-400" : "border-[#fdfdfd4d]"
            } border-[1px] border-solid contact-input input-style  p-[10px] bg-transparent text-[16px] font-[400] input-form`}
            type="text"
            placeholder={"www.instagram.com"}
            value={fetchPlace.instagram}
            name="instagram"
            onChange={handleChange}
          />
          {/* <label className="text-[red]" htmlFor="text">{errorMessage?.full_name && t("error_msg")}</label> */}
        </div>
        <div className="max-sm:w-full w-[350px] flex flex-col gap-2">
          <label
            className="text-[#CECFD2] text-[14px] font-[500]"
            htmlFor="text"
          >
            Telegram sahifasi uchun havola
          </label>
          <input
            className={`${
              false ? " border-red-400" : "border-[#fdfdfd4d]"
            } border-[1px] border-solid contact-input input-style  p-[10px] bg-transparent text-[16px] font-[400] input-form`}
            type="text"
            placeholder={"www.teleram.me"}
            name="telegram"
            value={fetchPlace.telegram}
            onChange={handleChange}
          />
          {/* <label className="text-[red]" htmlFor="text">{errorMessage?.full_name && t("error_msg")}</label> */}
        </div>
        <div className="max-sm:w-full w-[350px] flex flex-col gap-2">
          <label
            className="text-[#CECFD2] text-[14px] font-[500]"
            htmlFor="text"
          >
            Telegram bot uchun havola
          </label>
          <input
            className={`${
              false ? " border-red-400" : "border-[#fdfdfd4d]"
            } border-[1px] border-solid contact-input input-style  p-[10px] bg-transparent text-[16px] font-[400] input-form`}
            type="text"
            placeholder={"www.teleram.bot"}
            name="telegram_bot"
            value={fetchPlace.telegram_bot}
            onChange={handleChange}
          />
          {/* <label className="text-[red]" htmlFor="text">{errorMessage?.full_name && t("error_msg")}</label> */}
        </div>
        <div className="max-sm:w-full w-[350px] flex flex-col gap-2">
          <label
            className="text-[#CECFD2] text-[14px] font-[500]"
            htmlFor="text"
          >
            Youtube uchun havola
          </label>
          <input
            className={`${
              false ? " border-red-400" : "border-[#fdfdfd4d]"
            } border-[1px] border-solid contact-input input-style  p-[10px] bg-transparent text-[16px] font-[400] input-form`}
            type="text"
            placeholder={"www.youtube.com"}
            name="youtube"
            value={fetchPlace.youtube}
            onChange={handleChange}
          />
          {/* <label className="text-[red]" htmlFor="text">{errorMessage?.full_name && t("error_msg")}</label> */}
        </div>
      </form>
    </div>
  );
};

export default Links;
